"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
/* global site */
window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var version = document.querySelector('html').getAttribute('code-version');
    console.log(" \uD83C\uDF89 Code Version:%c ".concat(version, " "), 'color: #ff0000');
    var lightbox = GLightbox();

    // Store application settings
    options = options || {};
    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }

    // Initialize child classes
    if (_typeof(site.Header) === 'object') {
      site.Header.init();
    }
    if (_typeof(site.Footer) === 'object') {
      site.Footer.init();
    }
    if (_typeof(site.Footer) === 'object') {
      site.Footer.init();
    }
    if (_typeof(site.HeroBanner) === 'object') {
      site.HeroBanner.init();
    }
    if (_typeof(site.Faq) === 'object') {
      site.Faq.init();
    }
    if (_typeof(site.Drawers) === 'object') {
      site.Drawers.init();
    }
    if (_typeof(site.Graphs) === 'object') {
      site.Graphs.init();
    }
    if (_typeof(site.PhoneBook) === 'object') {
      site.PhoneBook.init();
    }
    if (_typeof(site.PartnersCompaniesList) === 'object') {
      site.PartnersCompaniesList.init();
    }
    if (_typeof(site.ImageWithCaption) === 'object') {
      site.ImageWithCaption.init();
    }
    if (_typeof(site.ConversionFactors) === 'object') {
      site.ConversionFactors.init();
    }
    if (_typeof(site.TabsContent) === 'object') {
      site.TabsContent.init();
    }
    if (_typeof(site.Navigation) === 'object') {
      site.Navigation.init();
    }
    if (_typeof(site.OverflightAreas) === 'object') {
      site.OverflightAreas.init();
    }
    if (_typeof(site.ImageGalery) === 'object') {
      site.ImageGalery.init();
    }
    if (_typeof(site.ContentWithSlider) === 'object') {
      site.ContentWithSlider.init();
    }
    if (_typeof(site.Breadcrumb) === 'object') {
      site.Breadcrumb.init();
    }
    if (_typeof(site.BlogHeroSlider) === 'object') {
      site.BlogHeroSlider.init();
    }
    if (_typeof(site.CustomSelect) === 'object') {
      site.CustomSelect.init();
    }
    if (_typeof(site.DataLayers) === 'object') {
      site.DataLayers.init();
    }
    if (_typeof(site.AnchorsInDrawers) === 'object') {
      site.AnchorsInDrawers.init();
    }
    if (_typeof(site.Buttons) === 'object') {
      site.Buttons.init();
    }
    if (_typeof(site.FilesUploads) === 'object') {
      site.FilesUploads.init();
    }
    console.table(options);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();